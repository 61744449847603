<template>
  <form-products
      :products.sync="offer.products"
      :allowEmpty="false"
      :disable-t-v-a="disableTVA"
      @changePreTaxAmount="$emit('changePreTaxAmount')"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FormProducts from '@/components/form/products/Products'

export default {
  components: {
    FormProducts
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    },
    disableTVA: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>