<template>
  <b-row>

    <!-- Billing date -->
    <b-col md="6">
      <field-date
          name="quoteDate"
          :model.sync="offer.billingDate"
      />
    </b-col>

    <!-- Payment term -->
    <b-col md="6">
      <field-select
          :model.sync="offer.paymentTerm"
          :name="'paymentTerms'"
          :options="paymentTerms"
          :label="'term'"
          :isRequired="true"
          :clearable="false"
      />
    </b-col>

    <!-- Expiration date -->
    <b-col md="4">
      <field-date
          name="expirationDate"
          :model.sync="offer.expirationDate"
          :isRequired="true"
          :minDate="offer.billingDate"
      />
    </b-col>

    <!-- Payment method -->
    <b-col md="4">
      <field-select
          :model.sync="offer.paymentMethod"
          :name="'paymentMethod'"
          :options="paymentMethods"
          :label="'name'"
          :isRequired="true"
          :clearable="false"
      />
    </b-col>

    <!-- Col: Discount -->
    <b-col md="4">
      <field-discount
          :name="'discount'"
          :discount.sync="offer.discount"
          :isDiscountPercent.sync="offer.isDiscountPercent"
          @input="$emit('changePreTaxAmount', 'discount', $event)"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '../../../../utils/utils'

import useAPI from '@/utils/useAPI'
import FieldInput from '@/components/input/Input'
import FieldSelect from '@/components/input/Select'
import FieldDate from '@/components/input/Date'
import FieldDiscount from '@/components/input/Discount'
import FieldEmployee from '@/components/input/Employee'

export default {
  components: {
    FieldInput,
    FieldSelect,
    FieldDate,
    FieldDiscount,
    FieldEmployee
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { paymentMethods, paymentTerms } = useAPI()

    const isEmployeePaymentMethod = computed(() => {
      return isObject(props.offer.paymentMethod) ? props.offer.paymentMethod.id == 5 : props.offer.paymentMethod == 5
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      isObject,

      // Data

      // Computed
      paymentMethods,
      paymentTerms,
      isEmployeePaymentMethod,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>