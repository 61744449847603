<template>
  <!--  <div>-->
  <b-row>
    <b-col cols="12">

      <!--        Header-->
      <b-card no-body>
        <b-card-body>
          <b-row>
            <!--      Left-->
            <b-col md="8">
              <b-row>

                <!-- Contact -->
                <b-col cols="12">
                  <field-contact
                      :customerCompany.sync="offer.customerCompany"
                      :customerIndividual.sync="offer.customerIndividual"
                      :isRequired="true"
                      @updated="updateAddresses"
                  />
                </b-col>

                <!-- Billing address -->
                <b-col
                    md="6"
                    :key="componentKey">
                  <field-select
                      v-if="offer.customerCompany != null || offer.customerIndividual != null"
                      :model.sync="offer.billingAddress"
                      :name="'billingAddress'"
                      :options="addresses"
                      :label="'_display'"
                      :isRequired="true"
                      :key="componentKey"
                  />
                </b-col>

                <!-- Delivery address -->
                <b-col md="6">
                  <field-select
                      v-if="offer.customerCompany != null || offer.customerIndividual != null"
                      :model.sync="offer.deliveryAddress"
                      :name="'deliveryAddress'"
                      :options="addresses"
                      :label="'_display'"
                      :isRequired="true"
                      :key="componentKey"
                      @updated="updateVatAllowed"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- Right -->
            <b-col md="4">
              <div class="w-100 mx-auto"
                   style="max-width: 12rem">

                <!--            Pre tax amount-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('PreTaxAmount:') }}
                  </p>
                  <p>
                    <b>{{ currency(offer.preTaxAmount) }}</b>
                  </p>
                </div>
                <!--            <VAT-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('VAT:') }}
                  </p>
                  <p>
                    <b>{{ currency(offer.vat) }}</b>
                  </p>
                </div>
                <hr class="my-50">

                <!--            Total amount-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('TotalAmount:') }}
                  </p>
                  <p>
                    <b>{{ currency(offer.totalAmount) }}</b>
                  </p>
                </div>

              </div>
            </b-col>

            <!-- Title -->
            <b-col md="9">
              <field-input
                  :name="'title'"
                  :model.sync="offer.title"
              />
            </b-col>

            <!-- Is order -->
            <b-col md="3">
              <field-switch
                  :name="'isOrder'"
                  :model.sync="isOrder"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>


      <!-- Tabs -->
      <b-card no-body>
        <b-card-body class="pt-0">
          <b-tabs>

            <!-- Products -->
            <b-tab
                active
                :title=" capitalize($tc('product', 2)) + ' (' + offer.products.length + ')'"
                :title-link-class="{'text-danger font-weight-bolder': isProductsTabError}"
            >
              <!--              <b-card-text>-->
              <tab-products
                  :offer="offer"
                  :disable-t-v-a="!isVatAllowed"
                  @changePreTaxAmount="changePreTaxAmount"
              />
              <!--              </b-card-text>-->
            </b-tab>

            <!-- Terms a conditions -->
            <b-tab
                :title="capitalize($t('terms&conditions'))"
                :title-link-class="{'text-danger font-weight-bolder': isTermsTabError}"
            >
              <!--              <b-card-text>-->
              <tab-terms
                  :offer="offer"
                  @changePreTaxAmount="changePreTaxAmount"
              />
              <!--              </b-card-text>-->

            </b-tab>

            <!-- Workflow & phase -->
            <b-tab :title="capitalize($t('workflow'))">
              <!--              <b-card-text>-->
              <tab-workflow :offer="offer"/>
              <!--              </b-card-text>-->
            </b-tab>

            <!-- Order -->
            <b-tab
                v-if="isOrder"
                :title-link-class="{'text-danger font-weight-bolder': isOrderTabError}"
                :title="capitalize($tc('order'))"
            >
              <!--              <b-card-text>-->
              <tab-order :offer="offer"/>
              <!--              </b-card-text>-->
            </b-tab>


          </b-tabs>
        </b-card-body>

      </b-card>

    </b-col>
  </b-row>
  <!--  </div>-->
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'
import { getRoute, isObject } from '@/utils/utils'

import FieldDate from '@/components/input/Date'
import FieldInput from '@/components/input/Input'
import FieldSelect from '../../../components/input/Select'
import FieldContact from '../../../components/input/Contact'
import FieldDiscount from '@/components/input/Discount'
import FieldSwitch from '@/components/input/Switch'
import FormProducts from '@/components/form/products/Products'
import FormOfferValidation from '@/components/form/OfferValidation'
import store from '@/store'
import useAPI from '@/utils/useAPI'

import TabProducts from './components/_tabProducts'
import TabTerms from './components/_tabTerms&conditions'
import TabWorkflow from './components/_tabWorkflow'
import TabOrder from './components/_tabOrder'

export default {
  components: {
    FieldDate,
    FieldInput,
    FieldSelect,
    FieldContact,
    FieldDiscount,
    FieldSwitch,
    FormProducts,
    FormOfferValidation,
    TabProducts,
    TabTerms,
    TabWorkflow,
    TabOrder
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    },
    formErrors: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)
    const displayWorkflow = ref(false)
    const addresses = ref([])
    const isOrder = ref(false)
    const isProductsTabError = ref(false)
    const isTermsTabError = ref(false)
    const isOrderTabError = ref(false)
    const isVatAllowed = ref(true)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { myCompany, paymentMethods, paymentTerms, workflows } = useAPI()

    // const selectedContact = computed(() => {
    //   if (props.offer.customerCompany) {
    //     if (!('addresses' in props.offer.customerCompany)) {
    //       store.dispatch('company/getCompany', props.offer.customerCompany.id)
    //           .then(company => {
    //             props.offer.customerCompany = company
    //             return company
    //           })
    //     } else {
    //       return props.offer.customerCompany
    //     }
    //
    //   } else if (props.offer.customerIndividual) {
    //     return props.offer.customerIndividual
    //   } else {
    //     return null
    //   }
    // })

    const productsSectionMaxHeight = computed(() => {
      let output = 350
      // if (selectedContact.value != null) {
      //   output -= 70
      // }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // watch(selectedContact, (val, old) => {
    //   // console.log(old)
    //   // console.log(val)
    //   if (val != null) {
    //     if (old == null || val.id != old.id) {
    //       let newAddress = old != null ? (val.addresses.length > old.addresses.length) : false
    //       // console.log(newAddress)
    //
    //       addresses.value = val.addresses
    //       if (val.company && 'addresses' in val.company) {
    //         addresses.value = addresses.value.concat(val.company.addresses)
    //       }
    //
    //       if (newAddress) {
    //         let selectedAddress = val.addresses[val.addresses.length - 1]
    //
    //         props.offer.billingAddress = selectedAddress.id
    //         props.offer.deliveryAddress = selectedAddress.id
    //
    //         if (!['FR', 'RE'].includes(selectedAddress.country)) {
    //           isVatAllowed.value = false
    //           changePreTaxAmount()
    //         }
    //
    //         componentKey.value++
    //       } else if (val.addresses.length >= 1) {
    //         let selectedAddress = val.addresses[0]
    //
    //         props.offer.billingAddress = selectedAddress.id
    //         props.offer.deliveryAddress = selectedAddress.id
    //
    //         if (!['FR', 'RE'].includes(selectedAddress.country)) {
    //           isVatAllowed.value = false
    //           changePreTaxAmount()
    //         }
    //
    //         componentKey.value++
    //       }
    //     }
    //
    //   } else {
    //     addresses.value = []
    //   }
    // })

    watch(() => props.offer.billingAddress, val => {
      updateVatAllowed()
    })

    watch(isOrder, (val) => {
      if (val == true) {
        props.offer.signedDocumentDate = (new Date()).toISOString().slice(0, 10)
      } else {
        props.offer.signedDocumentNumber = null
        props.offer.signedDocumentDate = null
        props.offer.signedDocument = null
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateVatAllowed = () => {
      if (
          props.offer.customerCompany != null &&
          ('addresses' in props.offer.customerCompany)) {
        let addressObject = props.offer.customerCompany.addresses.find(ad => ad.id == props.offer.billingAddress)

        if (!['FR', 'RE'].includes(addressObject.country)) {
          // isVatAllowed.value = false
          //
          // props.offer.products.forEach(p => {
          //   p.vat = 0
          //   p.totalAmount = p.preTaxAmount
          // })

          changePreTaxAmount()
        } else {
          isVatAllowed.value = true


          // props.offer.products.forEach(p => {
          //   let vatRateId = isObject(p.vatRate)?p.vatRate.id:p.vatRate
          //   p.vat = store.getters['vat/getVat'](vatRateId).rate * p.preTaxAmount
          //   p.totalAmount = parseFloat(p.preTaxAmount) + parseFloat(p.vat)
          // })

          changePreTaxAmount()

        }
      }
    }

    const changePreTaxAmount = (input = null, value = null) => {
      let subPreTaxAmount = 0
      props.offer.preTaxAmount = 0
      props.offer.vat = 0
      props.offer.totalAmount = 0

      props.offer.products.forEach(product => {
        // console.log(product)
        subPreTaxAmount += parseFloat(product.preTaxAmount)

        if (isVatAllowed.value) {
          props.offer.vat += parseFloat(product.vat)
        }
      })

      let discount = parseFloat(props.offer.discount ? props.offer.discount : 0)
      let isDiscountPercent = props.offer.isDiscountPercent
      if (input == 'discount') {
        if (typeof value === 'boolean') {
          isDiscountPercent = value
        } else {
          discount = parseFloat(value ? value : 0)
        }
      }

      if (discount != 0) {
        if (isDiscountPercent) {
          props.offer.preTaxAmount = parseFloat(
              subPreTaxAmount * (1 - discount / 100)
          ).toFixed(2)

          props.offer.vat = parseFloat(
              props.offer.vat * (1 - discount / 100)
          ).toFixed(2)

        } else {
          props.offer.preTaxAmount = subPreTaxAmount - discount
          props.offer.vat = 0

          let discountPercent = discount / subPreTaxAmount

          props.offer.products.forEach(product => {
            let discountedPreTaxAmount = parseFloat(product.preTaxAmount) * (1 - discountPercent)
            let rate = store.getters['vat/getVat'](isObject(product.vatRate) ? product.vatRate.id : product.vatRate).rate

            props.offer.vat += discountedPreTaxAmount * rate
          })

        }
      } else {
        props.offer.preTaxAmount = subPreTaxAmount
      }

      if (props.offer.preTaxAmount < 0) {
        props.offer.preTaxAmount = 0
        props.offer.vat = 0
      }

      // let duty = parseFloat(props.offer.duty ? props.offer.duty : 0)
      // props.offer.preTaxAmount += duty
      // props.offer.vat += parseFloat(duty * 0.2).toFixed(2)

      let tax = parseFloat(props.offer.tax ? props.offer.tax : 0)

      // console.log(props.offer)
      // if (props.offer.billingAddress != null && props.offer.billingAddress.country != "FR") {
      //   props.offer.vat = 0
      // }

      props.offer.totalAmount =
          parseFloat(props.offer.preTaxAmount) +
          parseFloat(props.offer.vat) +
          tax

      // componentKey.value++
    }

    const updateAddresses = () => {
      if (props.offer.customerCompany != null) {
        if (
            ('addresses' in props.offer.customerCompany) &&
            props.offer.customerCompany.addresses.length > 0
        ) {
          addresses.value = props.offer.customerCompany.addresses
          props.offer.billingAddress = props.offer.customerCompany.addresses[0].id
          props.offer.deliveryAddress = props.offer.customerCompany.addresses[0].id
        } else {
          addresses.value = []
        }
      } else if (props.offer.customerIndividual != null) {
        // populateAddresses(false)
        addresses.value = props.offer.customerIndividual.addresses
        if (props.offer.customerIndividual.company && 'addresses' in props.offer.customerIndividual.company) {
          addresses.value = addresses.value.concat(props.offer.customerIndividual.company.addresses)
        }

        if (props.offer.customerIndividual.addresses.length > 0) {
          props.offer.billingAddress = props.offer.customerIndividual.addresses[props.offer.customerIndividual.addresses.length - 1].id
          props.offer.deliveryAddress = props.offer.customerIndividual.addresses[props.offer.customerIndividual.addresses.length - 1].id
        }
      } else {
        addresses.value = []
      }

      updateVatAllowed()

      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name == 'Offers') displayWorkflow.value = true

    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data
      componentKey,
      displayWorkflow,
      addresses,
      isOrder,
      isProductsTabError,
      isTermsTabError,
      isOrderTabError,
      isVatAllowed,

      // Computed
      myCompany,
      paymentMethods,
      paymentTerms,
      // selectedContact,
      productsSectionMaxHeight,
      workflows,

      // Methods
      updateVatAllowed,
      changePreTaxAmount,
      updateAddresses
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    formErrors: function (val) {
      this.isProductsTabError = false
      this.isTermsTabError = false
      this.isOrderTabError = false

      for (const [field, errorArrray] of Object.entries(val)) {
        // console.log(field, errorArrray);

        if (
            errorArrray.length > 0 &&
            (
                field.startsWith('article') || field.startsWith('article') ||
                field.startsWith('prix unitaire') || field.startsWith('unit price') ||
                field.startsWith('quantité') || field.startsWith('quantity')
            )
        ) {
          this.isProductsTabError = true
        }

        if (
            (
                (field == 'conditions de paiement' || field == 'payment terms') ||
                (field == 'moyen de paiement' || field == 'payment method')
            ) &&
            errorArrray.length > 0
        ) {
          this.isTermsTabError = true
        }

        if (
            (field == 'date de signature' || field == 'signature date') &&
            errorArrray.length > 0) {
          this.isOrderTabError = true
        }

      }
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/invoice.scss";
@import '~@/assets/scss/vue/libs/vue-select.scss';
</style>